import {Controller} from "@hotwired/stimulus";

const CONTAINS_DIGIT_REGEX = /\d+/;
const MAX_ADDRESS_LENGTH = 50;

export default class extends Controller {
  static targets = ["container", "address", "addressModal"];

  connect() {
    this.required();

    if (this.observer) {
      this.observer.disconnect();
    }

    // Use MutationObserver to watch for class changes
    const observer = new MutationObserver(() => {
      this.required();
    });

    observer.observe(this.containerTarget, {
      attributes: true,
      attributeFilter: ["class"],
    });
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  required() {
    const isRequired = !this.containerTarget.classList.contains("hidden");

    const elements = this.containerTarget.querySelectorAll('[data-required="true"]');
    for (const element of elements) {
      element.required = isRequired;
    }
  }

  showError(element, message) {
    const error = element.querySelector(".error");

    if (!error.classList.contains("hidden")) return;

    error.classList.remove("hidden");
    error.innerHTML = message;
  }

  removeError(element) {
    const error = element.querySelector(".error");

    error.classList.add("hidden");
    error.innerHTML = "";
  }

  openAddressModal() {
    if (!this.addressModalTarget) return;

    const modalController = this.application.getControllerForElementAndIdentifier(this.addressModalTarget, "modal");

    if (!modalController) return;

    modalController.open();
  }

  closeAddressModal() {
    localStorage.setItem("addressModalDismissed", "true");
  }

  clearAddressModal() {
    localStorage.removeItem("addressModalDismissed");
  }

  isAddressValid() {
    const value = this.addressTarget.querySelector("input").value;
    const isRequired = !this.containerTarget.classList.contains("hidden");
    const isAddressModalDismissed = localStorage.getItem("addressModalDismissed");

    if (value.length > MAX_ADDRESS_LENGTH) {
      this.showError(
        this.addressTarget,
        `Street and house number can't be longer than ${MAX_ADDRESS_LENGTH} characters`,
      );
      return false;
    }

    if (value && !CONTAINS_DIGIT_REGEX.test(value)) {
      if (isRequired && !isAddressModalDismissed) {
        this.openAddressModal();
        return false;
      }
    }

    this.removeError(this.addressTarget);
    return true;
  }

  submit(e) {
    if (this.isAddressValid()) return;

    e.preventDefault();
  }
}
